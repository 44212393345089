import { createStore } from 'vuex';
import { saveAs } from 'file-saver';
import axios from "axios";
import router from "@/router";

export default createStore({
    state: {
        jwt: localStorage.getItem('t'),
        endpoints: {
            obtainJWT: process.env.VUE_APP_BACKEND_URL + '/auth/obtain_token',
            refreshJWT: process.env.VUE_APP_BACKEND_URL + '/auth/refresh_token'
        },
        isAuthenticated: false,
        token: '',
        isLoading: false,
        user: {
            is_superuser: false,
            is_staff: false,
        },
        userDetail: {},
        users: [],
        errors: [],
        category: {
            teams: {}
        },
        categories: [],
        seasons: [],
        season: {},
        teams: [],
        team: {
            season: {name: ''}
        },
        players: [],
        player: {
            teams: []
        },
        active: 'home',
        desktop: 'home',
        event: {},
        events: [],
        sumEvents: [],
        average: [],
        start: '',
        templates: [],
        template: {},
        evaluation: {}
  },
  getters: {
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('t')) {
        state.jwt = localStorage.getItem('t')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },
    logout(state) {
      state.user = {}
      state.team = {}
      state.isAuthenticated = false
      state.isAdmin = false
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setIsAuthenticated(state, value) {
      state.isAuthenticated = value
    },
    setActive(state, status) {
      state.active = status
    },
    updateToken(state, newToken){
      localStorage.setItem('t', newToken);
      state.jwt = newToken;
    },
    removeToken(state){
      localStorage.removeItem('t');
      state.jwt = null;
    },
    addToErrors(state, error) {
      state.errors.push(error)
    },
    resetErrors(state) {
      state.errors = []
    },
    setUser(state, user) {
      state.user = user
    },
    setUsers(state, users) {
      state.users = users
    },
    setCategories(state, categories) {
      state.categories = categories
    },
    setCategory(state, category) {
      state.category = category
    },
    setTeam(state, team) {
      state.team = team
    },
      setTeams(state, teams) {
          state.teams = teams
      },
    setSeasons(state, seasons) {
      state.seasons = seasons
    },
      setSeason(state, season) {
          state.season = season
      },
    setPlayers(state, players) {
      state.players = players
    },
    setPlayer(state, player) {
      state.player = player
    },
      setUserDetail(state, user) {
      state.userDetail = user
    },
      setEvents(state, events) {
          state.events = events
      },
      setSumEvents(state, events) {
          state.sumEvents = events
      },
      setEvent(state, event) {
          state.event = event
      },
      setAverage(state, avg) {
          state.average = avg
      },
      setDesktop(state, desktop) {
          state.desktop = desktop
      },
      setStart(state, start) {
          state.start = start
      },
      setTemplate(state, template) {
          state.template = template
      },
      setTemplates(state, templates) {
          state.templates = templates
      },
      setEvaluation(state, evaluation) {
          state.evaluation = evaluation
      },
  },
  actions: {
        async login(context, path) {
            await axios.get("/auth/users/me/")
                .then(async response => {
                    context.commit('setUser', response.data)
                    context.commit('setIsAuthenticated', true)
                    await context.dispatch('getCurrentSeason')
                    if (path) {
                        await router.push(path)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
      async signIn(context, formData) {
      context.commit('resetErrors')
      axios.defaults.headers.common.Authorization = ""
      await axios
          .post("/auth/token/login/", formData)
          .then(async response => {
            await context.commit('setIsAuthenticated', true)
            const token = response.data.auth_token
            await context.commit('updateToken', token)
            axios.defaults.headers.common.Authorization = "Token " + token
            await context.dispatch('login', {name: 'home'})
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                context.commit('addToErrors',`${error.response.data[property]}`)
              }
            } else {
              context.state.errors.push('Something went wrong. Please try again')

              console.log(JSON.stringify(error))
            }
          })
    },
      async signUp(context, formData) {
      context.commit('resetErrors')
      axios.defaults.headers.common.Authorization = ""
      await axios
          .post("/auth/users/", formData)
          .then(async response => {
            await context.dispatch('signIn', {
              email: formData.email,
              password: formData.password
            })
          })
          .catch(e => {
                  console.log(e)
              })
    },
      async getUsers(context) {
      await axios
          .get("/api/users/")
          .then(async response => {
            context.commit('setUsers', response.data)
            context.commit('setUserDetail', {})
          })
          .catch(e => {
                  console.log(e)
              })
    },
      async getUser(context, id) {
          await axios.get(`/api/users/${id}/`)
              .then(response => {
                  context.commit('setUserDetail', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async deleteUser(context, id) {
          await axios.delete(`/api/users/${id}/`)
              .then(response => {
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async createUser(context, formData) {
          await axios.post('api/create-user/', formData)
              .then(response => {
              })
              .catch(error => {
                  console.log(error)
              })
      },
      async updateUser(context, {id, formData}) {
      await axios
          .put(`/api/users/${id}/`, formData)
          .then(async response => {
            context.commit('setUser', response.data)
          })
          .catch(e => {
              console.log(e)
          })
    },
      async updateMe(context, formData) {
          await axios
              .put(`/api/users-me/`, formData)
              .then(async response => {
                  context.commit('setUser', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async updateUserDetails(context, {id, formData}) {
          await axios
              .put(`/api/users-details/${id}/`, formData)
              .then(async response => {
                  context.commit('setUserDetail', response.data)
              })
              .catch(error => {
                  console.log(error)
              })
      },
      async uploadUserPicture(context, {id, formData}) {
      axios
          .post(`/api/users/${id}/picture/`, formData)
          .then(response => {
            context.commit('setUser', response.data)
          })
          .catch(e => {
              console.log(e)
          })
    },
      async resetPwd(context, formData) {
          await axios
              .post("/auth/users/reset_password_confirm/", formData)
              .then(async response => {
                  await router.push({name: 'login'})
              })
              .catch(error => {
                  console.log(error)
              })
      },
      async getCategories(context) {
      await axios
          .get("/api/categories/")
          .then(async response => {
            context.commit('setCategories', response.data)
          })
          .catch(e => {
                  console.log(e)
              })
    },
      async getBaseCategories(context) {
          await axios
              .get("/api/base-categories/")
              .then(async response => {
                  context.commit('setCategories', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async createCategory(context, formData) {
      await axios.post('api/categories/', formData)
          .then(response => {
            context.dispatch('getCategories')
          })
          .catch(error => {
            console.log(error)
          })
    },
      async getCategory(context, id) {
      await axios
          .get(`/api/categories/${id}/`)
          .then(async response => {
            context.commit('setCategory', response.data)
          })
          .catch(e => {
                  console.log(e)
              })
    },
      async updateCategory(context, {id, formData}) {
      await axios
          .put(`/api/categories/${id}/`, formData)
          .then(async response => {
            context.commit('setCategory', response.data)
          })
          .catch(e => {
                  console.log(e)
              })
    },
      async deleteCategory(context, id) {
      await axios
          .delete(`/api/categories/${id}/`)
          .then(async response => {
          })
          .catch(e => {
                  console.log(e)
              })
    },
      async createTeam(context, {id, formData}) {
      await axios.post('api/teams/', formData)
          .then(async response => {
            await context.dispatch('getClub', id)
          })
          .catch(error => {
            console.log(error)
          })
    },
      async getTeam(context, id) {
      await axios
          .get(`/api/teams/${id}/`)
          .then(async response => {
            context.commit('setTeam', response.data)
          })
          .catch(e => {
                  console.log(e)
              })
    },
      async getTeamEvents(context, id) {
          await axios.get(`/api/teams/${id}/events/`)
              .then(response => {
                  context.commit('setEvents', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async getTeams(context, id) {
          await axios
              .get(`/api/teams/`)
              .then(async response => {
                  context.commit('setTeams', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async updateTeam(context, {id, formData}) {
          await axios.put(`/api/teams/${id}/`, formData)
              .then(response => {
                  context.commit('setTeam', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async updateTeamPlayers(context, {id, formData}) {
          await axios.put(`/api/players-teams/${id}/`, formData)
              .then(response => {
                  context.commit('setTeam', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async getTeamEventsPlayer(context, formData) {
          await axios.post(`/api/events-players-teams/${formData.id}/`, formData)
              .then(response => {
                  context.commit('setPlayers', response.data.data.players)
                  context.commit('setAverage', response.data.data.ratio)
                  delete response.data.data.players
                  delete response.data.data.ratio
                  delete response.data.data.index
                  context.commit('setSumEvents', response.data.data)
                  context.commit('setStart', response.data.start_date)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async exportData(context, formData) {
          await axios.post(`/api/teams/${formData.id}/export/`, formData)
              .then(response => {
                  context.commit('setPlayers', response.data.data.players)
                  context.commit('setAverage', response.data.data.ratio)
                  delete response.data.data.players
                  delete response.data.data.ratio
                  delete response.data.data.index
                  context.commit('setSumEvents', response.data.data)
                  context.commit('setStart', response.data.start_date)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async getMyTeams(context) {
          await axios
              .get(`/api/my-teams/`)
              .then(async response => {
                  context.commit('setTeams', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async getThisSeasonTeams(context) {
          await axios
              .get(`/api/this-season-teams/`)
              .then(async response => {
                  context.commit('setTeams', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async deleteTeam(context, id) {
          await axios.delete(`/api/teams/${id}/`)
              .then(response => {
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async createSeason(context, formData) {
      await axios
          .post("/api/seasons/", formData)
          .then(async response => {
          })
          .catch(error => {
            console.log(error)
          })
    },
      async getSeasons(context) {
      await axios.get(`/api/seasons/`)
          .then(response => {
            context.commit('setSeasons', response.data)
            return response.data
          })
          .catch(e => {
            console.log(e)
          })
    },
      async getCurrentSeason(context) {
          await axios.get(`/api/current-season/`)
              .then(response => {
                  context.commit('setSeason', response.data)
                  return response.data
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async deleteSeason(context, id) {
      await axios.delete(`/api/seasons/${id}/`)
          .then(response => {
            context.dispatch('getSeasons')
          })
          .catch(e => {
            console.log(e)
          })
    },
      async getPlayers(context) {
      await axios.get(`/api/players/`)
          .then(response => {
            context.commit('setPlayers', response.data)
          })
          .catch(e => {
            console.log(e)
          })
    },
      async getPlayer(context, id) {
      await axios.get(`/api/players/${id}/`)
          .then(response => {
            context.commit('setPlayer', response.data)
          })
          .catch(e => {
            console.log(e)
          })
    },
      async createPlayer(context, formData) {
      await axios.post(`/api/players/`, formData)
          .then(response => {
          })
          .catch(e => {
            console.log(e)
          })
    },
      async updatePlayer(context, {id, formData}) {
          await axios.put(`/api/players/${id}/`, formData)
              .then(async response => {
                  context.commit('setPlayer', response.data)
                  await context.dispatch('getPlayers')
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async deletePlayer(context, id) {
          await axios.delete(`/api/players/${id}/`)
              .then(response => {
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async deletePlayers(context, id) {
          await axios.get(`/api/delete_players/`)
              .then(response => {
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async createRole(context, formData) {
          await axios.post(`/api/roles/`, formData)
              .then(response => {
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async deleteRole(context, id) {
          await axios.delete(`/api/roles/${id}`)
              .then(response => {
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async getTodayEvents(context) {
          await axios.get(`/api/today-events/`)
              .then(response => {
                  context.commit('setEvents', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async getNextEvent(context) {
          await axios.get(`/api/next-event/`)
              .then(response => {
                  context.commit('setEvent', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async getMyEvents(context, formData) {
          await axios.post(`/api/my-events/`, formData)
              .then(response => {
                  context.commit('setEvents', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async getEvents(context, formData) {
          await axios.get(`/api/events/`, formData)
              .then(response => {
                  context.commit('setEvents', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async getEvent(context, id) {
          await axios.get(`/api/events/${id}/`)
              .then(response => {
                  context.commit('setEvent', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async createEvent(context, formData) {
          await axios.post(`/api/events/`, formData)
              .then(response => {
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async batchCreateEvent(context, formData) {
          await axios.post(`/api/batch-events/`, formData)
              .then(response => {
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async updateEvent(context, {id, formData}) {
          await axios.put(`/api/events/${id}/`, formData)
              .then(response => {
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async deleteEvent(context, id) {
          await axios.delete(`/api/events/${id}/`)
              .then(response => {
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async updateAvailability(context, {id, formData}) {
          await axios.put(`/api/availabilities/${id}/`, formData)
              .then(response => {
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async uploadPlayers(context, {id, payload}) {
          const formData = new FormData()
          formData.append('file', payload)
          await axios.post(`/api/teams/${id}/players/`, formData)
              .then(response => {

              })
              .catch(e => {
                  console.log(e)
              })
      },
      obtainToken(username, password){
      const payload = {
        username: username,
        password: password
      }
      axios.post(this.state.endpoints.obtainJWT, payload)
          .then((response)=>{
            this.commit('updateToken', response.data.token);
          })
          .catch((error)=>{
            console.log(error);
          })
    },
      refreshToken(){
      const payload = {
        token: this.state.jwt
      }
      axios.post(this.state.endpoints.refreshJWT, payload)
          .then((response)=>{
            this.commit('updateToken', response.data.token)
          })
          .catch((error)=>{
            console.log(error)
          })
    },
      inspectToken(){
      const token = this.state.jwt;
      if(token){
        const decoded = jwt_decode(token);
        const exp = decoded.exp
        const orig_iat = decode.orig_iat
        if(exp - (Date.now()/1000) < 1800 && (Date.now()/1000) - orig_iat < 628200){
          this.dispatch('refreshToken')
        } else if (exp -(Date.now()/1000) < 1800){
          // DO NOTHING, DO NOT REFRESH
        } else {
          // PROMPT USER TO RE-LOGIN, THIS ELSE CLAUSE COVERS THE CONDITION WHERE A TOKEN IS EXPIRED AS WELL
        }
      }
    },
      async batchCreatePlayer(context) {
          await axios.post(`/api/generate-players/`)
              .then(response => {
              })
              .catch(e => {
                  console.log(e)
              })
      },
      // Template
      async createTemplate(context, formData) {
          await axios.post(`/api/templates/`, formData)
              .then(response => {
                  context.commit('setTemplate', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async getTemplate(context, id) {
          await axios.get(`/api/templates/${id}/`)
              .then(response => {
                  context.commit('setTemplate', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async getTemplates(context) {
          await axios.get(`/api/templates/`)
              .then(response => {
                  context.commit('setTemplates', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async updateTemplate(context, {id, formData}) {
          await axios.put(`/api/templates/${id}/`, formData)
              .then(response => {
                  context.commit('setTemplate', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async deleteTemplate(context, id) {
          await axios.delete(`/api/templates/${id}/`)
              .then(async () => {
                  await context.dispatch('getTemplates')
              })
              .catch(e => {
                  console.log(e)
              })
      },
      // Section
      async createSection(context, formData) {
          await axios.post(`/api/sections/`, formData)
              .then(async response => {
                  await context.dispatch('getTemplate', response.data.template)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async updateSection(context, formData) {
          await axios.put(`/api/sections/${formData.id}/`, formData)
              .then(async response => {
                  await context.dispatch('getTemplate', response.data.template)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async deleteSection(context, formData) {
          await axios.delete(`/api/sections/${formData.id}/`)
              .then(async () => {
                  await context.dispatch('getTemplate', formData.template)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      // Competence
      async createCompetence(context, {formData, templateId}) {
          await axios.post(`/api/competences/`, formData)
              .then(async () => {
                  await context.dispatch('getTemplate', templateId)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async updateCompetence(context, {id, formData, templateId}) {
          await axios.put(`/api/competences/${id}/`, formData)
              .then(async () => {
                  await context.dispatch('getTemplate', templateId)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async deleteCompetence(context, {id, templateId}) {
          await axios.delete(`/api/competences/${id}/`)
              .then(async () => {
                  await context.dispatch('getTemplate', templateId)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      // Evaluation
      async createEvaluation(context, formData) {
          await axios.post(`/api/create_evaluations/`, formData)
              .then(async () => {
                  await context.dispatch('getPlayer', formData.player)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async getEvaluation(context, id) {
          await axios.get(`/api/evaluations/${id}/`)
              .then(response => {
                  context.commit('setEvaluation', response.data)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async deleteEvaluation(context, id) {
          await axios.delete(`/api/evaluations/${id}/`)
              .then(response => {
              })
              .catch(e => {
                  console.log(e)
              })
      },
      async exportEvaluation(context, id) {
          await axios.get(`/api/export_evaluations/${id}/`)
              .then(response => {
                  const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                  saveAs(pdfBlob, 'report.pdf');
              })
              .catch(e => {
                  console.log(e)
              })
      },
      // Evaluation Competence
      async updateEvalCompetence(context, {id, formData, evaluationId}) {
          await axios.put(`/api/eval_competences/${id}/`, formData)
              .then(async () => {
                  await context.dispatch('getEvaluation', evaluationId)
              })
              .catch(e => {
                  console.log(e)
              })
      },
      // Evaluation Section
      async updateEvalSection(context, {id, formData, evaluationId}) {
          await axios.put(`/api/eval_sections/${id}/`, formData)
              .then(async () => {
                  await context.dispatch('getEvaluation', evaluationId)
              })
              .catch(e => {
                  console.log(e)
              })
      },
  },
  modules: {
  }
})
