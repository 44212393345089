import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "@/store";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requireLogin: true,
      active: 'home',
      desktop: 'home',
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/users/SignInView.vue')
  },
  {
    path: '/change-password/',
    name: 'change-pwd',
    component: () => import('../views/password/ChangePwdView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings',
      desktop: 'myProfile',
    }
  },
  {
    path: '/reset-password/',
    name: 'reset-pwd',
    component: () => import('../views/password/ForgotPwdView.vue'),
  },
  {
    path: '/password-reset/:uid/:token',
    name: 'pwd-reset',
    component: () => import('../views/password/ResetPwdView.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/SettingsView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings',
      desktop: 'settings',
    }
  },
  {
    path: '/me',
    name: 'my-profile',
    component: () => import('../views/users/MyProfileView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings',
      desktop: 'myProfile',
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/users/UsersView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'users',
    }
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('../views/users/UserView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'users',
    }
  },
  {
    path: '/create-user',
    name: 'create_user',
    component: () => import('../views/users/CreateUserView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'users',
    }
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('../views/categories/CategoriesView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'teams',
    }
  },
  {
    path: '/categories/:id',
    name: 'category',
    component: () => import('../views/categories/UpdateCategoryView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'teams',
    }
  },
  {
    path: '/create-category',
    name: 'create_category',
    component: () => import('../views/categories/CreateCategoryView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'teams',
    }
  },
  {
    path: '/category/:id/create-team',
    name: 'create_team',
    component: () => import('../views/teams/CreateTeamView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'teams',
    }
  },
  {
    path: '/team/:id/',
    name: 'team',
    component: () => import('../views/teams/TeamView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings',
      desktop: 'myTeams',
    }
  },
  {
    path: '/my-teams/',
    name: 'my_teams',
    component: () => import('../views/teams/MyTeamsView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings',
      desktop: 'myTeams',
    }
  },
  {
    path: '/create-season',
    name: 'create_season',
    component: () => import('../views/seasons/CreateSeasonView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'seasons',
    }
  },
  {
    path: '/seasons',
    name: 'seasons',
    component: () => import('../views/seasons/SeasonView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'seasons',
    }
  },
  {
    path: '/players',
    name: 'players',
    component: () => import('../views/players/PlayersView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'players',
    }
  },
  {
    path: '/players/:id',
    name: 'player',
    component: () => import('../views/players/PlayerView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings',
      desktop: 'players',
    }
  },
  {
    path: '/create-player',
    name: 'create_player',
    component: () => import('../views/players/CreatePlayerView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'players',
    }
  },
  {
    path: '/update-player',
    name: 'update_player',
    component: () => import('../views/players/UpdatePlayerView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'players',
    }
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('../views/events/EventsView.vue'),
    meta: {
      requireLogin: true,
      active: 'events',
      desktop: 'events',
    }
  },
  {
    path: '/event/:id',
    name: 'event',
    component: () => import('../views/events/EventView.vue'),
    meta: {
      requireLogin: true,
      active: 'events',
      desktop: 'events',
    }
  },
  {
    path: '/create-event',
    name: 'create_event',
    component: () => import('../views/events/CreateEventView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'events',
      desktop: 'events',
    }
  },
  {
    path: '/update-event/:id',
    name: 'update_event',
    component: () => import('../views/events/UpdateEventView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'events',
      desktop: 'events',
    }
  },
  {
    path: '/batch-create-event',
    name: 'batch_create_event',
    component: () => import('../views/events/BatchCreateEventView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'events',
      desktop: 'events',
    }
  },
  {
    path: '/team-stats/:id',
    name: 'team_stats',
    component: () => import('../views/teams/TeamStatsView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'myTeams',
      desktop: 'myTeams',
    }
  },
  {
    path: '/import/',
    name: 'import',
    component: () => import('../views/imports/ImportView.vue'),
    meta: {
      requireLogin: true,
      requireAdmin: true,
      active: 'settings',
      desktop: 'import',
    }
  },
  {
    path: '/templates/',
    name: 'templates',
    component: () => import('../views/templates/TemplatesView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings',
      desktop: 'templates',
    }
  },
  {
    path: '/templates/:id',
    name: 'template',
    component: () => import('../views/templates/TemplateView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings',
      desktop: 'templates',
    }
  },
  {
    path: '/evaluations/:id',
    name: 'evaluation',
    component: () => import('../views/evaluations/EvaluationView.vue'),
    meta: {
      requireLogin: true,
      active: 'settings',
      desktop: 'players',
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'login', query: { to: to.path } });
  } else if (to.matched.some(record => record.meta.requireAdmin) && !store.state.user.is_staff) {
    next({ name: 'home'})
  } else {
    store.commit('setActive', to.meta.active)
    store.commit('setDesktop', to.meta.desktop)
    next()
  }
})

export default router
