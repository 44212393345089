<template>
  <div class="relative flex items-center bg-primary text-secondary h-16">
    <button v-if="back" @click="$router.back()" class="absolute left-4">
      <ChevronLeftIcon class="h-6 w-6"></ChevronLeftIcon>
    </button>
    <h1 class="w-full text-center font-extralight text-xl flex justify-center items-center h-full">{{ title }}</h1>
  </div>
</template>

<script>
import {ChevronLeftIcon} from '@heroicons/vue/24/outline';

export default {
  name: "MobileHeader",
  props: ['title', 'back'],
  components: {
      ChevronLeftIcon
  }
}
</script>

<style scoped>

</style>
