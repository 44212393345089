<template>
  <div class="flex flex-col w-full items-center overflow-y-auto">
    <MobileHeader class="block md:hidden w-full absolute h-16" title="Ombrage"></MobileHeader>
    <div class="flex flex-col h-full w-full text-primary text-left gap-6 overflow-y-auto">
      <div class="w-full text-left px-4 pt-10">
        <h1 class="text-2xl font-medium">Hey {{ user.first_name}}!</h1>
        <h2>Quoi de prévu pour aujourd'hui ?</h2>
      </div>
      <div class="flex flex-col flex-grow gap-4">
        <div class="w-full flex flex-col gap-4">
          <div class="pl-8">
            <div class="btn-black w-fit px-3 py-1" >
              Prochain événement
            </div>
          </div>

          <div v-if="event.team" class="flex-grow flex flex-col gap-4 overflow-y-auto pb-8 px-8">
            <EventCard :event="event" :name="event.team"></EventCard>
          </div>
          <div v-else class="pb-8">
            <div class="mx-8 px-4 py-3 rounded-lg bg-gray-300 bg-opacity-30 text-center text-gray-400 shadow flex items-center justify-between">
              Vous n'avez pas d'événement à venir.
            </div>
          </div>

        </div>
        <div class="flex-grow flex flex-col gap-4">
          <div class="pl-8">
            <div class="btn-black w-fit px-3 py-1" >
              Aujourd'hui
            </div>
          </div>
          <div v-if="events.length" class="flex-grow flex flex-col gap-4 overflow-y-auto pb-10 px-8">
            <EventCard v-for="event in events" :event="event" :name="event.team"></EventCard>
          </div>
          <div v-else class="mx-8 px-4 py-3 rounded-lg bg-gray-300 bg-opacity-30 text-center text-gray-400 shadow flex items-center justify-between">
            Vous n'avez pas d'événement aujourd'hui.
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>

import EventCard from "@/components/cards/EventCard";
import MobileHeader from "@/components/MobileHeader";
export default {
  name: 'HomeView',
  components: {MobileHeader, EventCard},
  async mounted () {
    await this.$store.commit('setIsLoading', true)
    await this.$store.dispatch('getTodayEvents')
    await this.$store.dispatch('getNextEvent')
    await this.$store.commit('setIsLoading', false)
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    events () {
      return this.$store.state.events
    },
    event () {
      return this.$store.state.event
    },
  }
}
</script>
