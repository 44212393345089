<template>
  <router-link :to="{name: 'event', params: {id: event.id}}"
               :class="(new Date(event.date)).setHours(0,0,0,0) < new Date().setHours(0,0,0,0) ? 'bg-gray-300' : ''"
               class="px-4 py-3 btn-black flex items-center justify-between">
    <div  class="rounded-2xl flex items-center gap-4">
      <div class="flex flex-col aspect-square h-16 items-center justify-around">
        <div>
          <p class="text-2xl text-center">{{ getDay(event.date) }}</p>
          <p class="text-tiny text-center">{{ getMonth(event.date) }}</p>
        </div>
      </div>
      <div class="flex flex-col justify-between">
        <h1 class="w-full font-extralight text-lg">{{event.name}}</h1>
        <p class="text-sm text-white capitalize">{{name}}</p>
        <p class="text-sm text-white capitalize">{{event.start.slice(0, -3)}} - {{event.place}}</p>
      </div>
    </div>
    <ChevronRightIcon class="text-white h-5 w-5"></ChevronRightIcon>
  </router-link>
</template>

<script>
import {ChevronRightIcon} from '@heroicons/vue/24/outline';

export default {
  name: "EventCard",
  props: ['event', 'name'],
  components: {
    ChevronRightIcon
  },
  methods: {
    getMonth (date) {
      const options = {month: 'short'}
      return new Intl.DateTimeFormat("fr-FR", options).format(new Date(date))
    },
    getDay (date) {
      return (new Date(date)).getDate()
    },
  }
}
</script>

<style scoped>

</style>
