<template>
  <div class="w-full bg-primary flex flex-inline text-nav text-white justify-around items-center pt-3 pb-7">
    <router-link :to="{name: 'events'}"
                 :class="active === 'events' ? 'text-secondary' :  ''"
                 class="flex flex-col items-center">
      <CalendarDaysIcon style="height: 24px"></CalendarDaysIcon>
      Evénements
    </router-link>
    <router-link :to="{name: 'home'}"
                 :class="active === 'home' ? 'text-secondary' :  ''"
                 class="flex flex-col items-center">
      <HomeIcon style="height: 24px"></HomeIcon>
      Home
    </router-link>
    <router-link :to="{name: 'settings'}"
                 :class="active === 'settings' ? 'text-secondary' :  ''"
                 class="flex flex-col items-center">
      <Cog6ToothIcon style="height: 24px"></Cog6ToothIcon>
      Réglages
    </router-link>
  </div>
</template>

<script>

import {HomeIcon, CalendarDaysIcon, Cog6ToothIcon} from '@heroicons/vue/24/outline';

export default {
  name: "MobileMenu",
  components: {
    HomeIcon,
    CalendarDaysIcon,
    Cog6ToothIcon
  },
  computed: {
    active () {
      return this.$store.state.active
    }
  }
}
</script>

<style scoped>

</style>
