<template>
  <div v-if="deferredPrompt" class="flex items-center justify-around absolute z-50 h-screen w-full backdrop-blur-sm">
    <div class="flex flex-col justify-between bg-white rounded-xl p-4 custom-shadow h-1/4 w-3/4 xl:w-1/2">
      <h3 class="text-sky-blue text-xl">Ajouter l'app à ton écran d'acceuil!</h3>
      <p class="text-gray-500">To fully enjoy the application's power you need to install it. To do so just click the button below and it will be added to your home screen.</p>
      <div class="flex gap-2 justify-end">
        <button
            class="btn-white text-sm px-4 py-2"
            @click="cancel">
          Annuler
        </button>
        <button
            class="inline-flex justify-center rounded-md border border-transparent bg-dark px-4 py-2 text-sm font-medium text-white outline-none"
            @click="clickCallback">
          Ajouter
        </button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'addToHomeScreen',
  data: () => ({
    deferredPrompt: null,
  }),
  mounted() {
    this.captureEvent()
  },
  methods: {
    captureEvent() {
      window.addEventListener('beforeinstallprompt', (e) => {
        // ! Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault()
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e
      })
    },
    clickCallback() {
      // Show the prompt
      this.deferredPrompt.prompt()
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // Call another function?
        }
        this.deferredPrompt = null
      })
    },
    cancel() {
      this.deferredPrompt = null
    }
  },
}
</script>
