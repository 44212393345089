<template>
  <div class="py-2 px-4 bg-primary overflow-y-auto">
    <!-- Home -->
    <router-link :to="{name: 'home'}" class="flex items-center rounded-lg py-4 w-full">
      <img class="" src="@/assets/Logo_2023-header-1024x341.png" alt="" />
    </router-link>
    <!-- Profile -->
    <div class="">
      <router-link :to="{name: 'my-profile'}"
                   :class="active === 'myProfile' ? 'text-secondary' : 'text-white hover:text-secondary hover:font-bold'"
                   class="flex items-center gap-6 rounded-lg object-cover py-2 px-2">
        <img class="h-9 w-9 rounded-full" :src="user.image" alt="" />
        <div>
          <h1 class="text-lg font-light flex items-center">{{ user.first_name }} {{ user.last_name }}</h1>
          <p class="text-sm font-extralight text-gray-400">Mon profil</p>
        </div>
      </router-link>
    </div>
    <!-- My Ombrage -->
    <div class="py-4 w-full">
      <h1 class="uppercase text-gray-400 mb-2">Mon espace</h1>
      <div class="flex items-center justify-between w-full" aria-label="Global">
        <div
            class="flex flex-col justify-center gap-2 text-white w-full">
            <router-link
                :to="item.to"
                v-for="item in navigation.filter(nav => !nav.admin)" :key="item.name" class="capitalize rounded-lg px-4 py-2 w-full"
                :class="active === item.active ? 'text-secondary' : 'hover:text-secondary'">
              {{ item.name }}
            </router-link>
        </div>
      </div>
    </div>
    <!-- Resp Cat -->
    <div v-if="isStaff || role === 'category_manager'" class="py-4 w-full">
      <h1 class="uppercase text-gray-400 mb-2">Resp catégorie</h1>
      <div class="flex items-center justify-between w-full" aria-label="Global">
        <div
            class="flex flex-col justify-center gap-2 text-white w-full">
          <router-link
              :to="{ name: 'templates'}"
              class="capitalize rounded-lg px-4 py-2 w-full"
              :class="active === 'templates' ? 'text-secondary' : 'hover:text-secondary'">
            Template d'évaluation
          </router-link>
        </div>
      </div>
    </div>
    <!-- Admin -->
    <div v-if="isStaff" class="w-full py-4 flex flex-col gap-1">
      <h1 class="uppercase text-gray-400 mb-2">Espace Admin</h1>
      <router-link
            :to="item.to"
            v-for="item in navigation.filter(nav => nav.admin)" :key="item.name" class="capitalize rounded-lg px-4 py-2 w-full"
            :class="active === item.active ? 'text-secondary' : 'text-white hover:text-secondary'">
          {{ item.name }}
        </router-link>
      </div>
    <!-- Action -->
    <div class="py-4 w-full">
      <h1 class="uppercase text-gray-400 mb-2">Mes actions</h1>
      <button
          @click="logout"
          class="flex gap-3 p-4 w-full text-attention">
        <ArrowRightOnRectangleIcon class="h-6 w-6"></ArrowRightOnRectangleIcon>
        Me déconnecter
      </button>
    </div>
  </div>
</template>

<script>
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon, ArrowRightOnRectangleIcon } from '@heroicons/vue/24/outline'
export default {
  name: "DesktopMenu",
  components: {
    Dialog, DialogPanel,
    Bars3Icon, XMarkIcon, ArrowRightOnRectangleIcon
  },
  data () {
    return {
      navigation: [
        { name: 'Mes événements', to: {name: 'events'}, admin: false, active: 'events' },
        { name: 'mes équipes', to: {name: 'my_teams'}, admin: false, active: 'myTeams'},
        { name: 'équipes', to: {name: 'categories'}, admin: true, active: 'teams'},
        { name: 'joueurs', to: {name: 'players'}, admin: true, active: 'players'},
        { name: 'utilisateurs', to: {name: 'users'}, admin: true, active: 'users'},
        { name: 'saisons', to: {name: 'seasons'}, admin: true, active: 'seasons' },
        { name: 'importer des données', to: {name: 'import'}, admin: true, active: 'import' },
      ],
    }
  },
  methods: {
    async logout () {
      this.$store.commit('setIsLoading', true)
      await this.$store.commit('logout')
      await this.$store.commit('removeToken')
      this.$router.push('/login')
      this.$store.commit('setIsLoading', false)
    },
  },
  computed: {
    active () {
      return this.$store.state.desktop
    },
    user () {
      return this.$store.state.user
    },
    isStaff () {
      return this.$store.state.user.is_staff
    },
    role () {
      return this.$store.state.user.role
    }
  }
}
</script>

<style scoped>

</style>
