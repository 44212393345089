<template>
  <!-- Update Prompt -->
  <div v-if="updateExists" class="flex items-center justify-around absolute z-50 h-screen w-full backdrop-blur-sm">
    <div class="flex flex-col gap-4 justify-between bg-white rounded-xl p-4 custom-shadow m-4 text-justify">
      <h3 class="text-primary text-xl">Nouvelle version!</h3>
      <p class="text-gray-500">Une nouvelle mise à jour est disponible. Cliquez sur le bouton pour la télécharger !</p>
      <button
          class="inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-secondary hover:bg-night-blue focus:outline-none focus-visible:ring-2 focus-visible:ring-night-blue focus-visible:ring-offset-2"
          @click="refreshApp">
        Mettre à jour
      </button>
    </div>
  </div>

  <!-- Loader -->
  <div
      v-if="this.$store.state.isLoading"
      class="flex items-center justify-around absolute z-50 h-screen w-full backdrop-blur-sm text-center">
    <div class="lds-dual-ring"></div>
  </div>

  <!-- Add to home screen prompt -->
  <AddToHomeScreen></AddToHomeScreen>

  <!-- App -->
  <div class="flex flex-col h-screen text-primary bg-grey items-center">
    <div class="flex w-full flex-grow overflow-y-auto">
      <DesktopMenu class="hidden md:block w-1/3 xl:w-1/5 text-tiny lg:text-base" v-if="$store.state.isAuthenticated"></DesktopMenu>
      <div
          :class="$route.name === 'login' ? 'w-full' : 'w-full md:w-2/3 xl:w-4/5'"
          class="flex justify-center">
        <router-view :key="$route.fullPath" class="bg-grey lato font-extralight overflow-y-auto w-full"/>
      </div>
    </div>
    <MobileMenu class="block md:hidden w-full" v-if="$store.state.isAuthenticated"></MobileMenu>
  </div>
</template>

<script>
import axios from "axios";
import update from './mixins/update'

import AddToHomeScreen from "@/components/AddToHomeScreen";
import MobileMenu from "@/components/MobileMenu";
import DesktopMenu from "@/components/DesktopMenu";
export default {
  components: {DesktopMenu, MobileMenu, AddToHomeScreen},
  mixins: [update],
  async beforeCreate() {
    document.title = 'Ombrage'
    await this.$store.commit('initializeStore')

    const token = this.$store.state.jwt

    if (token) {
      axios.defaults.headers.common['Authorization'] = "Token " + token
      await this.$store.dispatch('login')
    } else {
      axios.defaults.headers.common['Authorization'] = ''
    }
  },
}
</script>

<style lang="scss">
.wrapper {
  width: 0.75%;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #fecf00 transparent #fecf00 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-loading-bar {
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  &.is-loading {
    height: 80px;
  }
}

</style>
